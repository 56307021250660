.optionsPanel {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  display: flex;
  align-items: center;
}
