/* body {
  color: #333;
} */
.rbc-calendar,
.shiftForm {
  padding-top: 20px;
}
.user {
  padding: 25px 0 0 0;
}
/* .form-group {
  widows: 100%;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
} */
.btn-warning,
.badge-warning {
  color: #fff;
}
.rbc-day-slot .rbc-event-content {
  white-space: pre-line;
}
